import React, { useState, useEffect } from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import axios from "axios"
import Iframe from "react-iframe"
import * as queryString from "query-string"

const Feedback = ({ location }) => {
  //const name = "Operario"
  let { id, cs, name, rep } = queryString.parse(location.search)

  const feedbck = "Sí"

  const [loading, setLoading] = useState(true)
  const [session, setSession] = useState("no sesion")

  const headers = {
    Authorization: "bearer 40b3ff5fdeaf4ca6851eecadd6eec23c",
  }

  useEffect(() => {
    getBotId()
  }, [])

  const getBotId = async () => {
    try {
      const getBotId = await axios({
        method: "get", //you can set what request you want to be
        url: "https://api.33bot.io/v1/conversation/chat/5f9aa2eb76d67c000831167d/bots",
        headers,
      })
      //const bot_id = getBotId.data[0].id

      const newSession = await axios({
        method: "post", //you can set what request you want to be
        url: "https://api.33bot.io/v1/conversation/create",
        data: {
          bot_id: getBotId.data[0].id,
        },
        headers,
      })

      const someSession = newSession.data.id
      setSession(someSession)

      const updateSession = await axios({
        method: "post", //you can set what request you want to be
        url: "https://api.33bot.io/v1/conversation/update",
        data: {
          session_id: someSession,
          global_vars: {
            dni: {
              text: id,
              value: id,
            },
            nombre: {
              text: name,
              value: name,
            },
            candidatura_seleccionada: {
              text: cs,
              value: cs,
            },
            repush: {
              text: rep,
              value: rep,
            },
            feedback: {
              text: feedbck,
              value: feedbck,
            },
          },
        },
        headers,
      })

      if (updateSession.data.status === "ok") {
        setLoading(false)
      }
    } catch (error) {
      console.log(error)
    }
    console.log(`iniciando chatbot con sesión ${session}`)
  }

  return (
    <>
      <Seo title="Talento Seur" />
      <div style={{ width: "100vw", height: "100vh" }}>
        {loading ? (
          <div>Cargando...</div>
        ) : (
          <Iframe
            url={`https://chat.33bot.io/5f9aa3fb04fef00008b048c4?r=web&close=0&session=${session}`}
            width="100%"
            height="100%"
            allow="camera;microphone"
            frameborder="0"
          />
        )}
      </div>
    </>
  )
}

export default Feedback
